import React from "react";
import "./index.css";

class Footer extends React.Component {
  render() {
    return <footer className="footer">© Ling's Tales 2024.</footer>;
  }
}

export default Footer;
